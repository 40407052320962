import React, { useCallback, useMemo } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { AppBar, Avatar, Box, Container, Tab, Tabs, Toolbar } from '@material-ui/core'
import { observer } from 'mobx-react-lite'
import clsx from 'clsx'

import logo from '../assets/logo.svg'
import profile from '../assets/profile.svg'

import { useLogic, useStore } from '../hooks/storeHook'
import { appRoutes } from '../common/appRoutes'
import { makeStyles } from '@material-ui/core/styles'

import { IntegrationType } from '../server/mpsklad_core/Entity/IntegrationType'

import { ImpersonationBar } from './ImpersonationBar'
import { UserSyncWarning } from './UserSyncWarning'
import { PriceIncreaseNotificationBar } from './PriceIncreaseNotificationBar'
import { MoySkladAppTrialNotificationBar } from './MoySkladAppTrialNotificationBar'
import { WbApiTokensExpirationNotificationBars } from './WbApiTokensExpirationNotificationBars'
import { StoreMatchingWarningBar } from './StoreMatchingWarningBar'
import { IntegrationTypeKey } from '../types/integrationTypeUtils'

interface TabInfo {
  label: string;

  integration: IntegrationTypeKey;

  route?: string;
}

export const Header = observer(() => {
  const logic = useLogic()
  const store = useStore()
  const location = useLocation()

  const {integration: integrationTypeKey} = {...appRoutes.CommonStore.base.parse(location.pathname)}

  const classes = useStyles()

  const tabs: TabInfo[] = useMemo(() => {
      const allowedTabs: TabInfo[] = []

      if (store.allowedIntegrations[IntegrationType.Ozon]) {
        allowedTabs.push(
          {
            label: 'OZON',
            integration: 'Ozon',
            route: appRoutes.CommonStore.products.route(
              {accountId: store.syncStore.ozonAccounts[0]?.id ?? 0, integration: 'Ozon'})
          })
      }

      if (store.allowedIntegrations[IntegrationType.Wildberries]) {
        allowedTabs.push(
          {
            label: 'WILDBERRIES',
            integration: 'Wildberries',
            route: appRoutes.CommonStore.products.route(
              {accountId: store.syncStore.wbAccounts[0]?.id ?? 0, integration: 'Wildberries'})
          })
      }

      if (store.allowedIntegrations[IntegrationType.YandexMarket]) {
        allowedTabs.push(
          {
            label: 'Я.МАРКЕТ',
            integration: 'YandexMarket',
            route: appRoutes.CommonStore.products.route(
              {accountId: store.syncStore.yandexMarketAccounts[0]?.id ?? 0, integration: 'YandexMarket'})
          })
      }

      allowedTabs.push({
        label: 'МОЙСКЛАД',
        integration: 'MoySklad',
        route: appRoutes.MoySklad.settings.route(
          {accountId: store.syncStore.moySkladAccount?.id, integration: 'MoySklad'})
      })

      return allowedTabs
    },
    [store.allowedIntegrations,
      store.syncStore.ozonAccounts,
      store.syncStore.wbAccounts,
      store.syncStore.yandexMarketAccounts,
      store.syncStore.moySkladAccount])

  const onTabChange = useCallback((_event: React.ChangeEvent<{}>) => {

    if (integrationTypeKey && integrationTypeKey !== 'MoySklad') {
      logic.setNavAccountFromSpecifiedStore(IntegrationType[integrationTypeKey])
    } else {
      logic.setHomeNavFirstAccount()
    }

  }, [integrationTypeKey, logic])

  return (
    <AppBar position="static" className={classes.appBar}>
      <Container maxWidth={false}>
        <Toolbar>
          <Box display="flex" justifyContent="space-around" alignItems="center" width="100%">
            <Box>
              <ImpersonationBar/>
              <UserSyncWarning/>
              <PriceIncreaseNotificationBar/>
              <MoySkladAppTrialNotificationBar/>
              <WbApiTokensExpirationNotificationBars/>
              <StoreMatchingWarningBar/>
            </Box>
            <Box className="logo_container">
              <img src={logo} alt="Logo"/>
            </Box>
            <Tabs
              value={tabs.findIndex(tabInfo => tabInfo.integration === integrationTypeKey)}
              onChange={onTabChange}
              className="navigation"
              TabIndicatorProps={{
                style: {display: 'none'}
              }}
            >
              {tabs.map((tab, index) => (
                <Tab
                  key={index}
                  label={tab.label}
                  component={NavLink}
                  to={tab.route ?? ''}
                  className={clsx(
                    'nav_link',
                    classes.tab,
                    tab.integration === integrationTypeKey && classes.navLinkUnderlined)}
                  disableRipple
                />
              ))}
            </Tabs>
            <Box className="user_account">
              <NavLink to={appRoutes.Account}>
                <Avatar src={profile} alt="Profile" style={{width: 30, height: 30}}/>
              </NavLink>
            </Box>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  )
})

const useStyles = makeStyles((theme) => ({
  appBar: {
    color: theme.palette.mpSkladSecondary.darkBlue1,
    background: theme.palette.mpSkladPrimary.white,
    borderBottom: '1px solid #DFE4EB'
  },
  tab: {
    minWidth: 50,
    marginRight: 40,
    '& span': {
      fontSize: 16,
      fontWeight: 400,
      lineHeight: '19.2px'
    },
    '&.MuiTab-textColorInherit': {
      opacity: '1'
    }
  },
  navLinkUnderlined: {
    '& span': {
      fontSize: 16,
      fontWeight: 700,
      lineHeight: '19.2px',
      display: 'inline-block',
      color: '#3987CF',
      position: 'relative',
      '&::after': {
        content: '""',
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: '-1px',
        height: '2px',
        backgroundColor: '#3987CF',
        display: 'block',
        width: '100%'
      }
    }
  }
}))