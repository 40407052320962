/** `true` when launching via `npm start` */
export const __DEV__ = process.env.NODE_ENV !== 'production'

// Environment variables are set by CI at build time, except NODE_ENV which is set by CRA

const envDeploymentType = process.env['REACT_APP_DEPLOYMENT']

// NOTE: Order matters for declarations below

const noDeploymentType = () => {
  throw new Error('Environment variable for deployment type was not set')
}

const badDeploymentType = () => {
  throw new Error(`Environment variable for deployment type is invalid: ${envDeploymentType}`)
}

const getDeploymentType =
  () => envDeploymentType ?? noDeploymentType()

export const __STAGING__ = !__DEV__ && getDeploymentType() === 'staging'

export const __PRODUCTION__ = !__DEV__ && getDeploymentType() === 'production'

export const getApiBaseUrl = () =>
  __DEV__
  ? '/api/'
  : __STAGING__
    ? 'https://dev2.mpsklad.ru/api/'
    : __PRODUCTION__
      ? 'https://app.mpsklad.ru/api/'
      : badDeploymentType()