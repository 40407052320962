import React from 'react'
import { observer } from 'mobx-react-lite'

import { Link } from '@material-ui/core'
import { AppNotificationBar } from './AppNotificationBar'

import { useStore } from '../hooks/storeHook'

import { UserAuthModel } from '../server/mpsklad_core/Models/UserAuthModel'

export const PriceIncreaseNotificationBar =
  observer(() => {
    const {user} = useStore()

    if (!user) {
      return null
    }

    return (
      <AppNotificationBar severity="warning" notificationId={createPriceNotificationId(user)}>
        {/* @formatter:off */}
        Повышение цен с 1 декабря — <Link href="https://help.mpsklad.ru/" target="_blank">успейте подключить тариф.</Link>
        {/* @formatter:on */}
      </AppNotificationBar>
    )
  })

const createPriceNotificationId =
  // Email is used to show notifications to admins
  (user: UserAuthModel) => `priceIncrease_${user.email}`