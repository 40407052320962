import { createTheme } from '@material-ui/core'
import { ruRU } from '@material-ui/core/locale'

// TODO: Move to app context?
export const appTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 1280,
      md: 1920,
      lg: 2560,
      xl: 3840
    }
  },
  typography: {
    fontFamily: 'Gilroy, Arial, sans-serif'
  },
  props: {
    MuiButtonBase: {
      disableRipple: true
    }
  },
  palette: {
    primary: {
      main: '#353535'
    },
    secondary: {
      main: '#2178B3'
    },
    info: {
      main: '#4A7DAC'
    },
    success: {
      main: '#61BD83'
    },
    warning: {
      main: '#F0AD65'
    },
    error: {
      main: '#F5998C'
    },
    background: {
      default: '#FFF'
    },
    mpSkladPrimary: {
      black: '#1F1F1F',
      blue: '#3987CF',
      gray: '#888888',
      white: '#FFFFFF'
    },
    mpSkladSecondary: {
      darkBlue1: '#1F364D',
      darkBlue2: '#4A7DAC',
      blue: '#AED8FF',
      lightBlue: '#E7F4FF',
      lightGray: '#D2D2D2',
      green: '#61BD83',
      lightRed: '#F5998C',
      orange: '#F0AD65'
    },
    mpSkladBackground: {
      lightGray1: '#F2F2F2',
      lightGray2: '#FBFBFD',
      lightGray3: '#F0F2F5'
    }
  }
}, ruRU)